<template>
  <el-dialog title="学历查询结果" width="500" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('学历查询结果')"></tool>
      <h2 class="risk-dialogTitle">学历查询结果</h2>
      <table class="risk-dialogTable" v-if="v.result.data.educationInfos" v-for="(item,index) in v.result.data.educationInfos" :key="index">
        <colgroup>
          <col width="150"/>
          <col width="250"/>
          <col width="150"/>
          <col width="250"/>
        </colgroup>
        <tbody>
          <tr>
            <td>教育类型</td>
            <td>{{item.educationType}}</td>
            <td>性别</td>
            <td>{{item.gender}}</td>
          </tr>
          <tr>
            <td>专业名称</td>
            <td>{{item.major}}</td>
            <td>学习形式</td>
            <td>{{item.learningForm}}</td>
          </tr>
          <tr>
            <td>入学日期</td>
            <td>{{item.timeEnrollment}}</td>
            <td>身份证</td>
            <td>{{item.identityNo}}</td>
          </tr>
          <tr>
            <td>学位</td>
            <td>{{item.arrangement}}</td>
            <td>毕业状态</td>
            <td>{{item.graduationStatus}}</td>
          </tr>
          <tr>
            <td>毕业日期</td>
            <td>{{item.arrangement}}</td>
            <td>证书编号</td>
            <td>{{item.graduationStatus}}</td>
          </tr>
          <tr>
            <td>姓名</td>
            <td>{{item.name}}</td>
            <td>照片</td>
            <td><img :src="'data:image/png;base64,' + item.photo" /></td>
          </tr>
          <tr>
            <td>生日</td>
            <td>{{item.birthday}}</td>
            <td>导师</td>
            <td>{{item.headmaster}}</td>
          </tr>
          <tr>
            <td>学校名称</td>
            <td>{{item.schoolName}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="risk-dialogTable" v-if="v.result.data.studentInfos" v-for="(item,index) in v.result.data.studentInfos" :key="index">
        <colgroup>
          <col width="150"/>
          <col width="250"/>
          <col width="150"/>
          <col width="250"/>
        </colgroup>
        <tbody>
          <tr>
            <td>学号</td>
            <td>{{item.studentNo}}</td>
            <td>民族</td>
            <td>{{item.nation}}</td>
          </tr>
          <tr>
            <td>教育类型</td>
            <td>{{item.educationType}}</td>
            <td>性别</td>
            <td>{{item.gender}}</td>
          </tr>
          <tr>
            <td>专业名称</td>
            <td>{{item.major}}</td>
            <td>班级</td>
            <td>{{item.classes}}</td>
          </tr>
          <tr>
            <td>学习形式</td>
            <td>{{item.academicPeriod}}</td>
            <td>入学时间</td>
            <td>{{item.timeEnrollment}}</td>
          </tr>
          <tr>
            <td>分支</td>
            <td>{{item.department}}</td>
            <td>身份证</td>
            <td>{{item.identityNo}}</td>
          </tr>
          <tr>
            <td>学位</td>
            <td>{{item.arrangement}}</td>
            <td>毕业日期</td>
            <td>{{item.dateGraduation}}</td>
          </tr>
          <tr>
            <td>姓名</td>
            <td>{{item.name}}</td>
            <td>照片</td>
            <td><img :src="'data:image/png;base64,' + item.photo" /></td>
          </tr>
          <tr>
            <td>毕业状态</td>
            <td>{{item.enrollmentStatus}}</td>
            <td>学校名称</td>
            <td>{{item.schoolName}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  mixins: [mixin],
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    }
  },
  created() {
    // 初始化数据
    this.v = {}
  }
}
</script>
